<template>
<v-chart class="chart" :option="option" />
</template>

<script>
import {
  use
} from "echarts/core";
import {
  CanvasRenderer
} from "echarts/renderers";
import {
  BarChart
} from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from "echarts/components";
import VChart, {
  THEME_KEY
} from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
]);
export default {
  name: 'StackedChart',
  components: {
    VChart
  },
  props: ['data', 'title'],
  provide: {
    [THEME_KEY]: "light"
  },
  computed: {
    option: function () {
      let series = [];
      let yData = [];
      let dataNull = [];
      let data03 = [];
      let data47 = [];
      let data81 = [];
      this.data.map(d => {
        dataNull.push(d.resNull);
        data03.push(d.res03);
        data47.push(d.res47);
        data81.push(d.res81);
        yData.push(d.formatSet);
      })
      return {
        title: {
          text: '各部门OKR评分分布'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: yData
        },
        series: [{
            name: '未评分',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: dataNull
          },
          {
            name: '0-0.3分',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: data03
          },
          {
            name: '0.4-0.7分',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: data47
          },
          {
            name: '0.8-1分',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: data81
          },
        ],
        color: ['SlateGrey', 'green', 'blue', 'orange']
      }
    }
  }
}
</script>
